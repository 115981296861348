import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getGroups } from "../store/actions.js";
import SelectTeamItem from "../components/Select_team/SelectTeamItem.jsx";
import Loader from "../components/Loader";

const Select_team = ({ history: routerHistory }) => {
  const dispatch = useDispatch();
  const groupList = useSelector(state => state.groupList);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const isGroupListEmpty = groupList.length === 0;
  const counterByActivityList = useSelector(state => state.groupListActivity);

  useEffect(() => {
    try {
      dispatch(getGroups()).then(data => {
        if (data.login_error) {
          setIsError(true);
        }
        setIsLoading(false);
      });
    } catch (error) {
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pressOnSelectGroup = id => {
    if (id) {
      routerHistory.push(`/game-field/${id}`);
    }
  };

  const memoizedGroupListArray = useMemo(() => {
    // sort obj from earlier time to latest
    return Object.keys(groupList)
      .map(key => groupList[key])
      .sort(function(a, b) {
        return new Date(a.SuTime) - new Date(b.SuTime);
      });
  }, [groupList]);

  return (
    <>
      {isLoading ? (
        <div className="select-team-loading">
          <Loader />
        </div>
      ) : (
        <div className="select-team">
          <div className="game-field-bg">
            <img src="/static/img/kiosk-bg-new.jpg" alt="" />
          </div>
          <div className="container">
            {isError ? (
              <div className="select-team-empty">
                <h3>Помилка під час завантаження даних. Перезавантажте сторінку.</h3>
              </div>
            ) : isGroupListEmpty ? (
              <div className="select-team-empty">
                <h3>Тренувань поки що не створено.</h3>
              </div>
            ) : (
              <div className="select-team-holder">
                {memoizedGroupListArray.map(item => {
                  const { Id } = item;
                  const counterByActivity = counterByActivityList[Id] || {};
                  const { ICount = "...", QCount = "...", MCount = "..." } = counterByActivity;
                  return (
                    <SelectTeamItem
                      key={Id}
                      id={Id}
                      ICount={ICount}
                      QCount={QCount}
                      MCount={MCount}
                      data={item}
                      pressOnSelectGroup={pressOnSelectGroup}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(Select_team);
