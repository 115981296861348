import React, { useState, createRef, useEffect } from "react";
import { useVideo } from 'react-use';
import { useDispatch, useSelector } from "react-redux";
import { deleteParticipantVideo } from "../../store/actions";

import { deleteVideoKey } from '../../services/constants';

const VideoBlockItem = ({block, index, videoBlockData, getVideoList}) => {
  const dispatch = useDispatch();
  const [video, state, controls, ref] = useVideo(
    <video src={block.url} />
  );
  const {userId, year, paginationNum} = videoBlockData;

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year  = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day   = date.getDate().toString().padStart(2, "0");
    return day + '.' + month + '.' + year;
  }

  const playVideo = (video, index) => {
    video.current.requestFullscreen();
    video.current.play();

    document.addEventListener('fullscreenchange', function() {
      let isFullScreen = document.fullscreenElement;

      if(isFullScreen === null){
        video.current.load();
      }
    });
  }

  // const getDuration = () => {
  //   let duration = state.buffered;
  //   if(duration.length > 0){
  //     return Math.round(duration[0].end).toString().padStart(2, "0");
  //   }else{
  //     return 1;
  //   }
  // }

  const deleteVideo = () => {
    let message = 'Щоб видалити відео, введіть пароль.';
    let result = window.prompt(message);

    if(result === deleteVideoKey){
      dispatch(deleteParticipantVideo(block.url)).then(() => {
        getVideoList(userId, year, paginationNum)
      });
    }else{
      alert('Невірний ключ-пароль');
    }
  }

  return (
    <div className="item-video-block">
      <div className="item-video-elem">
        {/* <video ref={videoRefs[index]} src={block.url}></video> */}
        {video}
        {/* <div className="item-video-duration">
          <div>00:{getDuration(block.url)}</div>
        </div> */}
        <div
          className="item-video-delete-btn"
          onClick={deleteVideo}
        >
          <img src={process.env.PUBLIC_URL + "/static/img/delete-icon.png"} alt="" />
        </div>
      </div>
      <div className="item-video-bottom">
        <div className="item-video-date">
          <span className="item-video-date-text">Дата: </span>
          <span>{timestampToDate(block.timestamp)}</span>
        </div>
        <div
        className="video-item-play-btn"
        onClick={() => {playVideo(ref, index)}}
        >
          <img src={process.env.PUBLIC_URL + "/static/img/play-video.svg"} alt="" />
        </div>
      </div>
    </div>
  );
};

export default VideoBlockItem;
