import React, { useState } from "react";

import classNames from "classnames";
import { Formik, Form, Field } from "formik";
import { championsTypes } from "./MemberPopup";

export const userPassword = "7312";

export const errorTypes = {
  required: "Поле обов'язкове",
  wrongPassword: "Неправильний пароль",
  default: "Сталась помилка"
};

const UserPopup = ({
  handleCancel,
  isDataLoading,
  setIsDataLoading,
  setIsDataCorrect,
  loadData,
  championType,
  setIsChampion,
  setIsProgressPopup
}) => {
  const [passwordValue, setPasswordValue] = useState("");
  const [userError, setUserError] = useState("");

  const userFormValidate = () => {
    if (passwordValue.length < 1) {
      setUserError(errorTypes.required);
    } else {
      setUserError(errorTypes.wrongPassword);
    }
  };

  const userFormOnSubmit = async () => {
    setIsDataLoading(true);
    setUserError("");

    try {
      if (passwordValue === userPassword) {
        if (handleCancel) {
          setIsDataCorrect(true);
        }
        if (setIsChampion) {
          setIsDataLoading(false);
          championType === championsTypes.add ? setIsChampion(true) : setIsChampion(false);
        }
        if (setIsProgressPopup) {
          setIsDataLoading(false);
          setIsProgressPopup(true);
        }
        if (loadData) {
          await loadData();
        }
        setIsDataLoading(false);
      } else {
        userFormValidate();
        setIsDataLoading(false);
      }
    } catch (e) {
      setUserError(errorTypes.default);
    }
  };

  const handlePasswordChange = event => {
    setPasswordValue(event.target.value);
    setUserError(event.target.value.length === 0 ? errorTypes.required : "");
  };

  const handleClickCancelButton = () => {
    if (handleCancel) {
      handleCancel(false);
    }
  };

  return (
    <>
      <div className="back-btn" onClick={handleClickCancelButton}>
        <img src={process.env.PUBLIC_URL + "/static/img/left-arrow-icon.png"} alt="back" />
        <span>Повернутись</span>
      </div>

      <div className="content-popup">
        <div className="item">
          <div className="title">
            <span>Підтвердження користувача</span>
          </div>
        </div>

        <div className="form-wrap">
          <Formik
            initialValues={{
              password: ""
            }}
            validateOnBlur={false}
            onSubmit={userFormOnSubmit}
          >
            {() => (
              <Form className="user-confirmation-form">
                <div className="input-container">
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    className={classNames("popup-custom-input input-password", {
                      "input-error": userError
                    })}
                    value={passwordValue}
                    onChange={handlePasswordChange}
                  />
                  <label
                    className={classNames("popup-label", {
                      "popup-label-filled": passwordValue,
                      "label-error": userError
                    })}
                    htmlFor="password"
                  >
                    Пароль
                  </label>
                  {userError ? <div className="popup-custom-input-error">{userError}</div> : null}
                </div>
                <button
                  type="submit"
                  disabled={isDataLoading}
                  className="btn btn-orange btn-form user-confirmation-btn"
                >
                  <span>Вхід</span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserPopup;
