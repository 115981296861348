import * as constants from "./storeConstants";

const initialState = {
  loginError: null,
  groupList: [],
  groupListActivity: {},
  groupSingle: {},
  activityParticipantsList: {},
  videoList: {},
  commentError: null,
  championError: null,
  championIds: [],
  trainingStatus: null,
  trainingStatusError: null,
};

const rootReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.AUTH_START:
      return state;
    case constants.AUTH_SUCCESS:
      return { ...state, loginError: null };
    case constants.AUTH_FAIL: {
      return { ...state, loginError: payload };
    }
    case constants.GET_GROUP_LIST_EMPTY:
    case constants.GET_GROUP_LIST: {
      return { ...state, groupList: payload };
    }
    case constants.GET_GROUP_SINGLE: {
      return { ...state, groupSingle: payload };
    }
    case constants.CLEAR_GROUP_SINGLE: {
      return { ...state, groupSingle: {} };
    }
    case constants.GET_GROUP_IQ_COUNTER: {
      return { ...state, groupListActivity: { ...state.groupListActivity, ...payload } };
    }
    case constants.GET_ACTIVITY_PARTICIPANTS_LIST: {
      return { ...state, activityParticipantsList: payload };
    }
    case constants.GET_VIDEO_LIST: {
      return { ...state, videoList: payload };
    }
    case constants.CLEAR_ACTIVITY_PARTICIPANTS_LIST: {
      return { ...state, activityParticipantsList: {} };
    }
    case constants.UPDATE_ACTIVITY_PARTICIPANT_STATUS: {
      const { Id, status } = payload;
      const copyActivityParticipantsList = { ...state.activityParticipantsList };
      const currentMember = copyActivityParticipantsList[Id];
      if (currentMember) {
        currentMember["SuIsPresent"] = status;
        return {
          ...state,
          activityParticipantsList: copyActivityParticipantsList
        };
      }
      return state;
    }
    case constants.UPDATE_MEMBER_AVATAR_IMG: {
      const { Id, filePath } = payload;
      const copyActivityParticipantsList = { ...state.activityParticipantsList };
      const currentMember = copyActivityParticipantsList[Id];
      if (currentMember) {
        currentMember["Contact"]["fileSystemImage"] = filePath;
        return {
          ...state,
          activityParticipantsList: copyActivityParticipantsList
        };
      }
      return state;
    }

    case constants.UPDATE_MEMBER_RECORD: {
      const { Id, filePath } = payload;
      const copyActivityParticipantsList = { ...state.activityParticipantsList };
      const currentMember = copyActivityParticipantsList[Id];
      if (currentMember) {
        currentMember["Contact"]["audio"] = filePath;
        return {
          ...state,
          activityParticipantsList: copyActivityParticipantsList
        };
      }
      return state;
    }
    case constants.SET_VIDEO: {
      return { ...state, video: payload };
    }

    case constants.ADD_MEMBER_COMMENT:
      return { ...state, commentError: null };
    case constants.ADD_MEMBER_COMMENT_FAIL: {
      return { ...state, commentError: payload };
    }

    case constants.ADD_CHAMPION:
      return { ...state, championError: null };
    case constants.ADD_CHAMPION_FAIL: {
      return { ...state, championError: payload };
    }

    case constants.SET_CHAMPIONS: {
      return { ...state, championIds: [...state.championIds, payload] };
    }
    case constants.DELETE_CHAMPIONS: {
      return { ...state, championIds: payload };
    }

    case constants.SET_TRAINING_STATUS: {
      return { ...state, trainingStatus: payload, trainingStatusError: null };
    }
    case constants.SET_TRAINING_STATUS_ERROR: {
      return { ...state, trainingStatusError: payload };
    }

    default:
      return state;
  }
};

export { initialState };
export default rootReducer;
