import React, { useState, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getParticipantVideos
} from "../store/actions";
import Loader from "./Loader";
import VideoBlockItem from "./Video_block/VideoBlockItem";

const MemberVideoList = ({Id}) => {
  const getParticipantVideosList = useSelector(state => state.videoList);
  const [curVideoYear, setCurVideoYear] = useState('');
  const [curPaginationNum, setCurPaginationNum] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [videoBlockData, setVideoBlockData] = useState(
    {
      userId: Id,
      year: curVideoYear,
      paginatioNum: curPaginationNum
    }
  )
  const { files = [], pagesAmount = [], years = [] } = getParticipantVideosList;
  const fileLength = files.length;
  const dispatch = useDispatch();
  const pageAmountArray = Array.from({length: pagesAmount}, (_, i) => i + 1);

  const getVideoList = (Id, year = '', pagination = 1) => {
    dispatch(getParticipantVideos(Id, year, pagination)).then(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  }

  const paginationArrows = (isNext = false) => {
    let curPaginationNumPrev = isNext ? curPaginationNum + 1 : curPaginationNum - 1;

    setCurPaginationNum(curPaginationNumPrev);
    getVideoList(Id, curVideoYear, curPaginationNumPrev);
  }

  useEffect(() => {
    getVideoList(Id, curVideoYear, curPaginationNum);
  }, [fileLength]);

  return (
   <>
     {isLoading ?
        <div className="video-loader">
          <Loader />
        </div>
      :
        <div className="member-video-list">
          {files.length !== 0 ?
            <div>
              <div className="row-years-list">
              {years.map((elem, index) => {
                if(curVideoYear.length <= 0){
                  setCurVideoYear(years[0])
                }
                return(
                  <div
                    className={(curVideoYear === elem ? 'item-year-active' : '') + ' item-year'}
                    key={index}
                    onClick={() => {
                      getVideoList(Id, elem);
                      setCurVideoYear(elem);
                      setCurPaginationNum(1);
                    }}
                  >
                    <span>{elem}</span>
                  </div>
                )
              })}
            </div>
            <div className="row-video-list">
              <div className="row-video-title">Все відео за рік</div>
              <div className="item-video-list">
                {files.map((elem, index) => {
                  return(
                    <VideoBlockItem
                      key={index}
                      block={elem}
                      index={index}
                      videoBlockData={videoBlockData}

                      getVideoList={getVideoList}
                    />
                  )
                })}
              </div>
            </div>
            { pageAmountArray.length > 1 ?
              <div className="row-pagination">
                <div className="item-pagination">
                  <div
                    className={(curPaginationNum <= 1 ? 'disabled' : 'enabled') + ' item-pagination-block '}
                    onClick={() => {
                      paginationArrows();
                    }}
                  >
                    <img src={process.env.PUBLIC_URL + '/static/img/pagination-prev.svg'} />
                  </div>
                  {pageAmountArray.map((elem, index) => {
                    if(curPaginationNum.length <= 0){
                      setCurPaginationNum(pageAmountArray[0])
                    }
                    return(
                      <div
                        className={(curPaginationNum === elem ? 'item-pagination-block-active' : '') + ' item-pagination-block'}
                        key={index}
                        onClick={() => {
                          getVideoList(Id, curVideoYear, elem);
                          setCurPaginationNum(elem);
                        }}
                      >
                        {elem}
                      </div>
                    )
                  })}
                  <div
                    className={(curPaginationNum >= pagesAmount ? 'disabled' : 'enabled') + ' item-pagination-block '}
                    onClick={() => {
                      paginationArrows(true);
                    }}
                  >
                    <img src={process.env.PUBLIC_URL + '/static/img/pagination-next.svg'} />
                  </div>
                </div>
              </div>
              :
              null
            }
            </div>
            :
            <div className="empty-video-list">
              <div className="empty-video-text">Відео відсутні</div>
            </div>
          }
        </div>
      }
   </>
  );
};

export default MemberVideoList;
