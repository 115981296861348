import axios from 'axios';
import { apiActions } from '../services/constants';

import {apiUrl,apiUploadImageUrl,apiUploadAudioUrl} from './constants'

const generateBodyData = data => {

  const BPMCSRF = localStorage.getItem('BPMCSRF');
  const BPMLOADER = localStorage.getItem('BPMLOADER');
  const UserName = localStorage.getItem('UserName');
  const _ASPXAUTH = localStorage.getItem('_ASPXAUTH');
  const BPMSESSIONID = localStorage.getItem('BPMSESSIONID');

  if (!BPMCSRF) throw new Error('Користувач не зареєстрований');

  //check if data is already formData
  if(data instanceof FormData){
    data.append('BPMCSRF', BPMCSRF);
    data.append('BPMLOADER', BPMLOADER);
    data.append('UserName', UserName);
    data.append('_ASPXAUTH', _ASPXAUTH);
    data.append('BPMSESSIONID', BPMSESSIONID);
    return data;
  }else {
    const fakeForm = new FormData();
    fakeForm.append('BPMCSRF', BPMCSRF);
    fakeForm.append('BPMLOADER', BPMLOADER);
    fakeForm.append('UserName', UserName);
    fakeForm.append('_ASPXAUTH', _ASPXAUTH);
    fakeForm.append('BPMSESSIONID', BPMSESSIONID);

    for (const key in data) {
      fakeForm.append(key, data[key]);
    }
    return fakeForm;
  }

}

async function detectLoseTokens(response,data,config) {
  // after every 10-15 min server lost tokens - so if message === 'Unauthorized' we re-login with new tokens and fire again api() from recursion
  if (response.data && response.data.message && (response.data.message === 'Unauthorized')) {

    await apiLogin();
    const secondResponse = await api(data, config);
    return secondResponse;
  }else if (response.data.message === 'Undescribed error') {
    alert(response.data.message)
  } else {
    return response
  }
}


const apiLogin = async (login, password) => {
  try {
    let actualLogin = login;
    let actualPassword = password;
    if (!login && !password) {
      actualLogin = localStorage.getItem('login');
      actualPassword = localStorage.getItem('password');
    }

    if (!actualLogin && !actualPassword) throw new Error('Не введено логін та пароль');

    const fakeForm = new FormData();
    fakeForm.append('login', actualLogin);
    fakeForm.append('password', actualPassword);
    fakeForm.append('action', apiActions.login);

    const response = await axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: apiUrl,
      data: fakeForm
    });
    if (response.data && response.data.login_error) {
      throw new Error(response.data.message);
    }

    const {
      data: { BPMCSRF = null, BPMLOADER, UserName, _ASPXAUTH, BPMSESSIONID }
    } = response;

    //check if success login
    if (BPMCSRF) {
      localStorage.setItem('login', actualLogin);
      localStorage.setItem('password', actualPassword);
      localStorage.setItem('BPMCSRF', BPMCSRF);
      localStorage.setItem('BPMLOADER', BPMLOADER);
      localStorage.setItem('UserName', UserName);
      localStorage.setItem('_ASPXAUTH', _ASPXAUTH);
      localStorage.setItem('BPMSESSIONID', BPMSESSIONID);
      return true;
    }

  }catch(e){
    //console.log(e)
    throw new Error(e);
  }
};

async function api(data = {}, config = {}) {
  const { method = 'post', url = apiUrl, ...restConfig } = config;

  try {
    const requestData = generateBodyData(data);
    const response = await axios({
      method,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${url}`,
      data: requestData,
      ...restConfig
    });

    const responseChecked = await detectLoseTokens(response,data,config)

    return responseChecked;
  } catch (error) {
    throw new Error(error);
  }
}

var CancelToken = axios.CancelToken;
var cancel;

async function autocompleteRequestApi(data, config = {}) {
  if (cancel !== undefined) {
    cancel();
  }
  const { method = 'post', url = apiUrl, ...restConfig } = config;

  try {
    const requestData = generateBodyData(data)

    const response = await axios({
      method,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `${url}`,
      data: requestData,
      ...restConfig
    });

    const responseChecked = await detectLoseTokens(response,data,config)

    return responseChecked;
  } catch (error) {
    if(!error.__CANCEL__) throw new Error(error);
  }

}

const apiComment = async (data) => {
  try {
    const requestData = generateBodyData(data);

    const response = await axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: apiUrl,
      data: requestData
    });
  }catch(e){
    throw new Error(e);
  }
};

const apiChampion = async (data) => {
  try {
    const requestData = generateBodyData(data);
    const response = await axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: apiUrl,
      data: requestData
    });
  }catch(e){
    throw new Error(e);
  }
};

const apiStatusTraining = async (data) => {
  try {
    const requestData = generateBodyData(data);
    const response = await axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: apiUrl,
      data: requestData
    });
  }catch(e){
    throw new Error(e);
  }
};

export { apiLogin, autocompleteRequestApi, apiComment, apiChampion, apiStatusTraining };
export default api;
