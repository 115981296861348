import React from "react";
import ChampionItem from "./ChampionItem";

const ChampionList = ({ list }) => {
  return (
    <div className="champion-list">
      {list.map((item, index) => (
        <ChampionItem item={item} key={index} />
      ))}
    </div>
  );
};

export default ChampionList;
