import React from 'react';

const Loader = () => {
  return (
    <div
      style={{
        flex: 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <svg
        style={{ width: 70, height: 70 }}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="58.0108" cy="71.9892" r="5" fill="#f26723">
          <animate
            attributeName="cx"
            values="80;50"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="cy"
            values="50;80"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="fill"
            values="#f26723;#5a4f93"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="28.0108" cy="58.0108" r="5" fill="#5a4f93">
          <animate
            attributeName="cx"
            values="50;20"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="cy"
            values="80;50.00000000000001"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="fill"
            values="#5a4f93;#f26723"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="41.9892" cy="28.0108" r="5" fill="#f26723">
          <animate
            attributeName="cx"
            values="20;49.99999999999999"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="cy"
            values="50.00000000000001;20"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="fill"
            values="#f26723;#5a4f93"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
        <circle cx="71.9892" cy="41.9892" r="5" fill="#5a4f93">
          <animate
            attributeName="cx"
            values="49.99999999999999;80"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="cy"
            values="20;49.99999999999999"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="fill"
            values="#5a4f93;#f26723"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
