import React from "react";
import VideoPlayerPopup from "./VideoPlayerPopup";

const VideoItemPopup = ({ handleVideoPlayerOpen, handleVideoPlayerClose, videoSrc }) => {
  const videoUrlParts = videoSrc.split("/");
  const videoUrlPart = videoUrlParts[videoUrlParts.length - 1].replace(".mp4", "");
  const videoName = videoUrlPart.charAt(0).toUpperCase() + videoUrlPart.slice(1);

  return (
    <div className="video-item-wrap" onClick={() => handleVideoPlayerOpen(videoSrc)}>
      <div className="video-preview">
        <VideoPlayerPopup handleVideoPlayerClose={handleVideoPlayerClose} videoSrc={videoSrc} />
      </div>

      <div className="description">
        <div className="title-description">
          <span>{videoName}</span>
        </div>

        <div className="play-icon">
          <img src={process.env.PUBLIC_URL + "/static/img/play-icon.svg"} />
        </div>
      </div>
    </div>
  );
};

export default VideoItemPopup;
