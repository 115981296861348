import React from 'react';

import { Route, HashRouter as Router, Switch, Redirect } from 'react-router-dom';

import Game_field from './pages/Game_field';
import Login from './pages/Login';
import Select_team from './pages/Select_team';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuth = localStorage.getItem('BPMCSRF');

  return (
    <Route render={props => (isAuth ? <Component {...props} /> : <Redirect to="/" />)} {...rest} />
  );
};

const Routes = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="App">
        <Switch>
          <ProtectedRoute path="/game-field/:id" exact component={Game_field} />
          <ProtectedRoute path="/select-team" component={Select_team} />

          <Route path="/login" component={Login} />
          <Route path="/" exact component={Login} />
          <Route component={Login} />
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
