import React from "react";
import VideoItemPopup from "./VideoItemPopup.jsx";

const VideoListPopup = ({
  isVideoClicked,
  setIsEnglishClicked,
  handleVideoPlayerOpen,
  handleVideoPlayerClose,
  videoTraining
}) => {
  const handleClickCancelButton = () => {
    if (setIsEnglishClicked) {
      setIsEnglishClicked(false);
    }
  };

  return (
    <>
      <div className="back-btn" onClick={handleClickCancelButton}>
        <img src={process.env.PUBLIC_URL + "/static/img/left-arrow-icon.png"} />
        <span>Повернутись</span>
      </div>

      <div className="content-popup video-content-popup">
        <div className="video-items-wrap">
          {videoTraining.map(videoSrc => (
            <VideoItemPopup
              key={videoSrc}
              isVideoClicked={isVideoClicked}
              handleVideoPlayerOpen={handleVideoPlayerOpen}
              handleVideoPlayerClose={handleVideoPlayerClose}
              videoSrc={videoSrc}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default VideoListPopup;
