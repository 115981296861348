import React from "react";
import { apiUrl } from "../../services/constants";

const VideoPlayerPopup = ({ videoSrc, handleVideoPlayerClose }) => {
  return (
    <div className="video-player-wrap">
      <div className="popup-cancel-btn video-cancel-btn" onClick={handleVideoPlayerClose}>
        <img src={process.env.PUBLIC_URL + "/static/img/cross-icon.svg"} alt="cancel" />
      </div>
      <div className="video">
        <video controls width="100%">
          <source src={`${apiUrl}/${videoSrc}`} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>
  );
};

export default VideoPlayerPopup;
