export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const GET_GROUP_LIST = 'GET_GROUP_LIST';
export const GET_GROUP_LIST_EMPTY = 'GET_GROUP_LIST_EMPTY';
export const GET_GROUP_SINGLE = 'GET_GROUP_SINGLE';
export const CLEAR_GROUP_SINGLE = 'CLEAR_GROUP_SINGLE';
export const GET_GROUP_IQ_COUNTER = 'GET_GROUP_IQ_COUNTER';

export const GET_ACTIVITY_PARTICIPANTS_LIST = 'GET_ACTIVITY_PARTICIPANTS_LIST';
export const UPDATE_ACTIVITY_PARTICIPANT_STATUS = 'UPDATE_ACTIVITY_PARTICIPANT_STATUS';
export const CLEAR_ACTIVITY_PARTICIPANTS_LIST = 'CLEAR_ACTIVITY_PARTICIPANTS_LIST';

export const GET_VIDEO_LIST = 'GET_VIDEO_LIST';
export const SET_VIDEO = 'SET_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';

export const UPDATE_MEMBER_AVATAR_IMG = 'UPDATE_MEMBER_AVATAR_IMG';
export const UPDATE_MEMBER_RECORD = 'UPDATE_MEMBER_RECORD';

export const ADD_MEMBER_COMMENT = 'ADD_MEMBER_COMMENT';
export const ADD_MEMBER_COMMENT_FAIL = 'ADD_MEMBER_COMMENT_FAIL';

export const ADD_CHAMPION = 'ADD_CHAMPION';
export const ADD_CHAMPION_FAIL = 'ADD_CHAMPION_FAIL';

export const DELETE_CHAMPION = 'DELETE_CHAMPION';
export const DELETE_CHAMPION_FAIL = 'DELETE_CHAMPION_FAIL';

export const SET_CHAMPIONS = 'SET_CHAMPIONS';
export const DELETE_CHAMPIONS = 'DELETE_CHAMPIONS';

export const SET_TRAINING_STATUS = 'SET_TRAINING_STATUS';
export const SET_TRAINING_STATUS_ERROR = 'SET_TRAINING_STATUS_ERROR';
