import React from "react";

const ChampionItem = ({ item }) => {
  const { month, year } = item;
  const getMonthName = (month) => {
    const months = [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень',
    ];

    if (month >= 1 && month <= 12) {
      return `${months[month - 1]}, `;
    } else {
      return '';
    }
  };

  const monthName = getMonthName(month);

  return (
    <span className="champion-month">{monthName}{year}</span>
  );
};

export default ChampionItem;
