import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
// eslint-disable-next-line
import { createStore, applyMiddleware } from 'redux';

//Middleware
// eslint-disable-next-line
import thunk from 'redux-thunk';
// eslint-disable-next-line
import { composeWithDevTools } from 'redux-devtools-extension';

// main routes
import Routes from './routes';

import reducers from './store/reducers.js';

// styles
import './style/index.scss';

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.querySelector('#root')
);
