export const checkMemberBirthday = (birthDate) => {
  const date = new Date();
  const birthday = new Date(birthDate);
  date.setHours(0, 0, 0, 0);
  date.setYear(0);
  birthday.setYear(0);

  if (date.valueOf() === birthday.valueOf()) {
    return true;
  } else {
    return false; // check
  }
};
