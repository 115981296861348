import React, { useRef, useEffect } from "react";

const StreamPreview = ({ stream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);

    return <video className="item-video item-video-stream"  ref={videoRef} autoPlay />;
};

export default StreamPreview;