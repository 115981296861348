import React from "react";

const CommentPatternItem = ({ comment, handleCommentsPatternClick }) => {
  return (
    <div className="comment-pattern" onClick={() => handleCommentsPatternClick(comment)}>
      <span>{comment}</span>
    </div>
  );
};

export default CommentPatternItem;
